<template>
	<div>
		<div class="jobdetail">
			<div class="jobdetail-content">
				<div class="jobdetail-header">
					<div class="jobdetail-header-left">
						<div class="pic">
							<img :src="info.peR_IMG_PATH? setuserlogo(info.peR_IMG_PATH): require('@/assets/img/tx.png')"
								alt="" />
						</div>
					</div>
					<div class="jobdetail-header-right">
						<div class="name">
							{{info.peR_NAME? this.zph || info.isDown==1? info.peR_NAME: utils.formatName(info.peR_NAME): "RC_"+info.peR_ID}}
						</div>
						<div class="flex1">
							<span>{{ info.peR_GENDER}}</span>
							<span>|</span>
							<span>{{info.age}}岁</span>
							<span>|</span>
							<span>{{info.peR_WORK_YEARS>0?info.peR_WORK_YEARS+"年":"无"}}工作经验</span>
							<span>|</span>
							<span>{{ info.xueli }}</span>
						</div>
						<div class="flex1" style="margin-top: 20px;font-size: 16px;">
							<span>
								<i class="el-icon-mobile-phone"></i>
								{{info.peR_CELLPHONE? info.isDown==1? info.peR_CELLPHONE: utils.phone(info.peR_CELLPHONE): ""}}
								{{info.peR_PHONE? info.isDown==1? info.peR_PHONE: utils.phone(info.peR_PHONE): ""}}
							</span>
							<span style="margin-left:67px;">
								<i class="el-icon-message"></i>
								{{ info.peR_EMAIL? info.isDown==1 ? info.peR_EMAIL: utils.phone(info.peR_EMAIL): "" }}
							</span>
						</div>
					</div>
					<div class="follow-Collection flex2" v-if="this.zph || (ispaybycomiddata == 0 && idcompanylogin)">
						<div class="follow" @click="info.isFav != 0 ? cancelfavuser( ) :favuser()"
							:class="{ active: info.isFav != 0 ? true :false  }">
							<i
								:class="info.isFav != 0 ? 'el-icon-star-on' : 'el-icon-star-off'"></i>{{ info.isFav != 0 ? "已收藏" : "加入收藏" }}
						</div>
						<div class="Collection" v-if="info.isDown == 0" @click="downloadper()">
							<i class="el-icon-download"></i>
							下载简历
						</div>
					</div>
				</div>
				<div class="jobdetail-contain">
					<div class="jobdetail-introduce">
						<div class="introduce-item" >
							<div class="introduce-h2">期望职位</div>
							<div class="introduce-text " v-for="item in worklist">
								<div style="width: 100%;">
									<span class="title">{{ item.name }}</span>
									<div class="flex1" style="width: 100%;">
										<p style="width: 50%;">期望薪资：{{setsalary(item.salary, item.salarye)}}</p>
										<p style="width: 50%;">期望城市：{{ CodeToText(item.percode) }}/{{ CodeToText(item.citycode) }}/{{ CodeToText(item.areacode) }}
										</p>
									</div>
									<div class="flex1"style="width: 100%;">
										<p style="width: 50%;">工作性质：{{item.nature}}</p>
										<p style="width: 50%;">期望行业：{{ item.hyName }}</p>
									</div>
								</div>
							</div>
						</div>
						<div class="introduce-item">
							<div class="introduce-h2">求职期望</div>
							<div class="introduce-text" style="justify-content: space-between;margin: 49px 0;">
								<div style="color: #101010;font-size: 14px;">
									<span
										style="margin-right: 10px;">{{ info.peR_HOPE_QTYQ ? info.peR_HOPE_QTYQ : "无" }}</span>|<span
										style="margin-left: 10px;margin-right: 10px;">
										{{ info.hope_quxian ? info.hope_sheng + info.hope_shi + info.hope_quxian : "无" }}</span>
								</div>
								<div style="color:#F66308;font-size: 18px;">
									{{ info.hope_xinzi ? info.hope_xinzi : "面议" }}
								</div>
							</div>
						</div>
						<div>
							<div class="introduce-item">
								<div class="introduce-h2">自我评价</div>
								<div class="introduce-text">
									<p v-html="info.peR_INTRODUCE"></p>
								</div>
							</div>
							<div class="introduce-item">
								<div class="introduce-h2">职业技能</div>
								<div class="introduce-text">
									<p v-html="info.peR_JNTC_INTRODUCE"></p>
								</div>
							</div>
							<div class="introduce-item">
								<div class="introduce-h2">工作经历</div>
								<div class="introduce-text flex4" v-for="item in infolist1">
									<div>
										<span class="title">{{ item.title }}</span>
										<div class="name">{{ item.name }}</div>
										<p>{{return2Br(item.infoDes)}}</p>
									</div>
									<div>{{utils.timeFormat(item.stateTime,3) }}-{{ utils.timeFormat(item.endTime,3) }}
									</div>
								</div>
							</div>
							<div class="introduce-item">
								<div class="introduce-h2">项目经历</div>
								<div class="introduce-text flex4" v-for="item in infolist3">
									<div>
										<span class="title">{{ item.title }}</span>
										<div class="name">{{ item.name }}</div>
										<p>{{return2Br(item.infoDes)}}</p>
									</div>
									<div>{{utils.timeFormat(item.stateTime,3) }}-{{ utils.timeFormat(item.endTime,3) }}
									</div>
								</div>
							</div>
							<div class="introduce-item">
								<div class="introduce-h2">教育经历</div>
								<div class="introduce-text flex4" v-for="item in infolist2">
									<div>
										<span class="title">{{ item.title }}</span>
										<div class="name">
											<span>{{ item.name }}</span>
											<span>|</span>
											<span>{{ item.infoDes }}</span>
										</div>
									</div>
									<div>{{utils.timeFormat(item.stateTime,3) }}-{{ utils.timeFormat(item.endTime,3) }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<el-dialog title="温馨提示" :visible.sync="centerDialogVisible" width="25%" center>
				<div class="tishi-a">尊敬的用户您好！</div>
				<div class="tishi-b">以下条件尚未满足,暂时无法查看详细简历</div>
				<div class="tishi-c flex" v-if="!idcompanylogin">
					<div class="left">
						<i class="el-icon-error"></i>
						企业账号登录
					</div>
					<div class="right" @click="goto('/login', { type: 2 }, 2)">
						前往登录
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
				<div class="tishi-c flex" v-else-if="ispaybycomiddata != 0">
					<div class="left">
						<i class="el-icon-error"></i>
						无有效网络招聘
					</div>
					<div class="right" @click="goto('/companycenter')">
						前往办理
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<div class="tishi-btn">
						<span @click="Complete()">我已完成以上操作</span>
					</div>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		getresumebyid,
		favuser,
		cancelfavuser,
		ispaybycomid,
		downloadper,
	} from "@/api/company";
	import {
		getinfolist,
		getworklist,
	} from "@/api/user";
	import {
		isnull,
		handleCofirm
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import {
		CodeToText
	} from "element-china-area-data";

	export default {
		data() {
			return {
				form: {
					name: "",
					region: "",
					industry: "",
				},
				form: {
					searchKey: "",
					region: "",
					industry: "",
					field: "",
					field1: "", //职位类型
					field2: "", //公司行业
					field3: "", //工作经验
					field4: "", //学历要求
					field5: "", //薪资要求
					field6: "", //融资阶段
					field7: "", //公司规模
					order: "", //排序
					pageNumber: 1,
					pageSize: 3,
				},
				worklist: [],
				infolist1: [],
				infolist2: [],
				infolist3: [],
				info: {},
				pid: "",
				uid: "",
				zwid: "",
				iscss: false,
				ispaybycomiddata: 0,
				jobinfo: {},
				userinfo: "",
				idcompanylogin: false,
				centerDialogVisible: false,
			};
		},
		created() {
			this.pid = this.$route.query.id;
			this.uid = this.$route.query.uid;
			this.zwid = this.$route.query.zwid;
			this.zph = this.$route.query.zph; //用于判断是否是招聘会简历，以便处理简历已读
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			if (this.userinfo && this.userinfo.coM_ID && !this.zph) {
				this.ispaybycomid();
				this.idcompanylogin = true;
			}
			this.getresumebyid();
			this.infolist()
			window.scrollTo(0, 0);
		},
		methods: {
			CodeToText(value) {
				return CodeToText[value];
			},
			setuserlogo(url) {
				var data = isnull(url) ? 0 : url.indexOf("http");
				return isnull(url) ?
					require("@/assets/img/logos.png") :
					data < 0 ?
					this.defimgurl + url :
					url;
			},
			infolist() {
				this.getworklist();
				this.getinfolist(1);
				this.getinfolist(2);
				this.getinfolist(3);
			},
			getworklist() {
				var _this = this;
				var par = {
					id: _this.pid,
				};
				getworklist(par).then((res) => {
					if (res.success) {
						_this.worklist = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			getinfolist(cid) {
				var _this = this;
				var par = {
					id: _this.pid,
					cid: cid,
				};
				getinfolist(par).then((res) => {
					if (res.success) {
						if (cid == 1)
							_this.infolist1 = res.data;
						if (cid == 2)
							_this.infolist2 = res.data;
						if (cid == 3)
							_this.infolist3 = res.data;
					} else {
						//_this.$message.error(res.msg);
					}
				});
			},
			return2Br(str) {
				return str.replace(/\r?\n/g, "<br />");
			},
			goto(url, data, type = 1) {
				this.resolvewin(url, data, type);
			},
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			ispaybycomid() {
				var _this = this;
				ispaybycomid({
					id: this.userinfo.coM_ID
				}).then((res) => {
					_this.ispaybycomiddata = res.data;
				});
			},
			setsalary(a, b) {
				return utils.setsalary(a,b)
			},
			getresumebyid() {
				var _this = this;
				if (isnull(_this.pid)) {
					return false;
				}
				let  comid=0;
				if (this.zph || (this.ispaybycomiddata == 0 && this.idcompanylogin)) {
					comid= this.userinfo.coM_ID;
				}
				var per = {
					id: _this.pid,
					uid: comid,
					zid: _this.zwid,
				};
				if (this.zph) {
					per.code = "1";
				}
				getresumebyid(per).then((res) => {
					if (res.success) {
						_this.info = res.data[0];
						_this.info.peR_INTRODUCE = _this.info.peR_INTRODUCE ? this.return2Br(_this.info
							.peR_INTRODUCE) : "暂未留下更多自我评价";
						_this.info.peR_JNTC_INTRODUCE = _this.info.peR_JNTC_INTRODUCE ? this.return2Br(_this.info
							.peR_JNTC_INTRODUCE) : "暂未留下更多职业技能";
						_this.info.peR_WORK_INTRODUCE = _this.info.peR_WORK_INTRODUCE ? this.return2Br(_this.info
							.peR_WORK_INTRODUCE) : "暂未留下更多工作经历";
						_this.info.age = this.getAge(_this.info.peR_BIRTHDAY)
						res.data[0].hy = res.data[0].peR_LASTLOAD ?
							this.utils.handleDateHY(res.data[0].peR_LASTLOAD) :
							"";
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//下载简历
			downloadper() {
				if (this.zph || (this.ispaybycomiddata == 0 && this.idcompanylogin)) {
					var _this = this;
					downloadper({
						id: this.userinfo.coM_ID,
						uid: _this.pid,
					}).then((res) => {
						if (res.success) {
							this.getresumebyid();
							_this.$message.success("简历已下载!");
						} else {
							_this.$message.error(res.msg);
						}
					});
				} else {
					this.centerDialogVisible = true;
				}
			},
			//人才收藏
			favuser() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					uid: _this.pid,
				};
				favuser(par).then((res) => {
					if (res.success) {
						this.getresumebyid();
						_this.$message.success("简历已收藏!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//计算年龄
			getAge(strAge) {
				var birArr = strAge.split("-");
				var birYear = birArr[0];
				var birMonth = birArr[1];
				var birDay = birArr[2];

				d = new Date();
				var nowYear = d.getFullYear();
				var nowMonth = d.getMonth() + 1; //记得加1
				var nowDay = d.getDate();
				var returnAge;

				if (birArr == null) {
					return false
				};
				var d = new Date(birYear, birMonth - 1, birDay);
				if (d.getFullYear() == birYear && (d.getMonth() + 1) == birMonth && d.getDate() == birDay) {
					if (nowYear == birYear) {
						returnAge = 0; // 
					} else {
						var ageDiff = nowYear - birYear; // 
						if (ageDiff > 0) {
							if (nowMonth == birMonth) {
								var dayDiff = nowDay - birDay; // 
								if (dayDiff < 0) {
									returnAge = ageDiff - 1;
								} else {
									returnAge = ageDiff;
								}
							} else {
								var monthDiff = nowMonth - birMonth; // 
								if (monthDiff < 0) {
									returnAge = ageDiff - 1;
								} else {
									returnAge = ageDiff;
								}
							}
						} else {
							return "出生日期晚于今天，数据有误"; //返回-1 表示出生日期输入错误 晚于今天
						}
					}
					return returnAge;
				} else {
					return ("输入的日期格式错误！");
				}
			},
			//取消人才收藏
			cancelfavuser() {
				var _this = this;
				handleCofirm('确认取消收藏该简历吗？', 'warning', ).then(res => {
					var par = {
						id: this.userinfo.coM_ID,
						uid: _this.pid,
					};
					cancelfavuser(par).then((res) => {
						if (res.success) {
							this.getresumebyid();
							_this.$message.success("已取消收藏!");
						} else {
							_this.$message.error(res.msg);
						}
					});
				}).catch(err => {
					//console.log('err', err) // cancel
				})
			},

			fl(data) {
				return data.split(",");
			},
			Complete() {
				location.reload();
			},
		},
	};
</script>
<style lang="scss" scoped>
	@media print {
		body {
			-webkit-print-color-adjust: exact;
			-moz-print-color-adjust: exact;
			-ms-print-color-adjust: exact;
			print-color-adjust: exact;
		}
	}

	.jobdetail-content {
		position: relative;
		width: 900px;
		margin: 0px auto;
		padding: 20px 0;
		background: url(../../assets/img/bgs.png) repeat #FFF;


		.jobdetail-header {
			// padding: 24px;
			// margin-bottom: 12px;
			position: relative;
			display: flex;
			align-content: center;
			// align-items: stretch;
			width: 900px;
			margin: 0 auto;
			height: 184px;

			.jobdetail-header-left {

				.pic {
					width: 85px;
					height: 85px;
					border-radius: 50%;
					overflow: hidden;
					margin-right: 20px;
					margin-top: 49.5px;
					margin-left: 55px;

					img {
						width: 100%;
					}
				}


				.jobdetail-header-title {
					line-height: 32px;
					font-size: 18px;
					color: #333;
					display: flex;
					justify-content: space-between;
					margin-bottom: 16px;

					.job-name {
						font-weight: bold;
					}

					.job-salary {
						font-weight: bold;
						color: #ff7630;
					}
				}

				.jobdetail-header-view {
					display: flex;
					align-items: center;
					margin-bottom: 12px;

					.jobdetail-header-item {
						width: 225px;
						text-align: left;
						font-size: 14px;
						color: #898989;
						display: flex;

						span {
							color: #666;
						}
					}

					.jobdetail-header-welfare {
						font-size: 14px;
						color: #898989;
						display: flex;
						flex-wrap: wrap;

						span {
							padding: 4px 8px;
							font-size: 12px;
							color: #66b1ff;
							background: #eee;
							border-radius: 2px;
							margin-right: 12px;
						}
					}
				}

				.jobdetail-header-view:last-child {
					margin-bottom: 0;
				}
			}

			.jobdetail-header-right {
				flex: 1;
				width: 100%;
				height: 184px;

				// text-align: right;
				// display: flex;
				// flex-direction: column;


				.name {
					color: #101010;
					font-size: 28px;
					text-align: left;
					font-family: SourceHanSansSC-black;
					font-weight: bold;
					margin-top: 27px;
					margin-bottom: 20px;
				}

				span {
					margin-right: 10px;
				}

				i {
					font-size: 24px;
				}

				.release-time {
					width: 100%;
					font-size: 14px;
					color: #787878;
					margin-bottom: 12px;
					height: 26px;
					line-height: 26px;
				}

			}

			.follow-Collection {
				width: 35%;
				display: flex;
				justify-content: flex-end;
				top: 20px;
				right: 20px;
				position: absolute;

				.follow,
				.Collection {
					display: flex;
					align-items: center;
					justify-content: center;
					color: #0089FF;
					cursor: pointer;
					border: 1px solid #0089FF;
					width: 140px;
					height: 40px;
					margin-left: 15px;

					i {
						font-size: 22px;
						margin-right: 8px;
					}
				}

				.Collection {
					color: #fff;
					background-color: #0089FF;
				}

				.active {
					color: #66b1ff;
					border: 1px solid #66b1ff;
				}
			}

			.apply-btn {
				width: 100%;
				align-self: flex-end;
				text-align: right;
			}
		}

		.introduce-text {

			.down-Collection {
				//width: 100%;
				//display: flex;
				//justify-content: flex-end;
				margin-top: 10px;

				.down {
					display: flex;
					align-items: center;
					justify-content: center;
					color: #66b1ff;
					cursor: pointer;
					border: 1px solid #66b1ff;
					width: 140px;
					height: 40px;
					border-radius: 8px;

					i {
						font-size: 22px;
						margin-right: 8px;
					}
				}
			}
		}

		.jobdetail-contain {
			width: 900px;
			margin: 0 auto;
			display: flex;
			align-items: stretch;
			position: relative;

			.jobdetail-introduce {
				flex: 1;
				width: 100%;
				padding: 0 55px;
				margin-top: 54px;

				.introduce-item {
					position: relative;
					padding-bottom: 16px;

					.introduce-h2 {
						padding-left: 24px;
						position: relative;
						color: rgba(16, 16, 16, 100);
						font-size: 16px;
						text-align: left;
						margin-bottom: 12px;
					}

					.introduce-h2::before {
						width: 10px;
						height: 24px;
						left: 0;
						top: 0px;
						bottom: 2px;
						border-radius: 2.5px;
						background: #0096FF;
						content: "";
						position: absolute;
					}

					.introduce-text {
						position: relative;
						text-align: left;
						font-size: 14px;
						color: #898989;
						display: flex;
						// flex-direction: column;
						margin: 34px 0;
						margin-top: 10px;
						color: #101010;

						h2 {
							font-size: 14px;
							font-weight: normal;
						}

						.title {
							font-size: 15px;
							font-weight: bold;
						}

						.name {
							margin-top: 10px;
							margin-bottom: 10px;
							font-size: 14px;
							color: #101010;

							span {
								margin-right: 10px;
							}
						}

						p {
							color: #898989;
							margin: 0px;
							padding: 0px;
							line-height: 26px;
						}

						.introduce-text-list {
							display: flex;
							align-items: center;
							margin-top: 12px;

							margin-bottom: 35px;


							div {
								margin-right: 10px;
							}

							span {
								display: block;
								width: 72px;
								font-size: 14px;
								color: rgba(16, 16, 16, 100);
								font-size: 18px;
							}

							a {
								text-decoration: none;
								color: #ff7630;
								font-weight: bold;
							}
						}

						img {
							width: 100%;
						}
					}
				}

				.introduce-item:first-child {
					border-top: none;
				}
			}

			.jobdetail-company {
				width: 380px;
				margin-left: 16px;

				.company-card {
					background: #fff;
					border-radius: 4px;
					margin-bottom: 16px;
					padding: 20px;

					.company-hd {
						display: flex;
						align-items: center;
						padding-bottom: 12px;
						border-bottom: 1px solid #eee;
						margin-bottom: 12px;

						.company-logo {
							width: 64px;
							height: 64px;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-right: 16px;

							img {
								width: 100%;
							}
						}

						.company-name {
							font-size: 18px;
							color: #000;
							font-weight: bold;
						}
					}

					.company-body {
						text-align: left;
						font-size: 15px;
						color: #898989;
						border-bottom: 1px solid #eee;

						div {
							padding-bottom: 16px;
						}

						span {
							color: #666;
						}
					}

					.company-btn {
						padding-top: 16px;
					}
				}

				.post-card {
					background: #fff;
					border-radius: 4px;
					margin-bottom: 16px;
					padding: 20px;

					.post-title {
						padding-left: 24px;
						position: relative;
						font-size: 18px;
						font-weight: bold;
						color: #000;
						text-align: left;
						margin-bottom: 12px;
					}

					.post-title::before {
						width: 5px;
						left: 0;
						top: 2px;
						bottom: 2px;
						border-radius: 2.5px;
						background: #66b1ff;
						content: "";
						position: absolute;
					}

					.post-cell {
						.position-item {
							padding: 16px;
							position: relative;
							background: #f2f2f2;
							margin-bottom: 16px;

							.position-hd {
								font-size: 16px;
								color: #409eff;
								display: flex;
								justify-content: space-between;
								margin-bottom: 4px;
								align-items: center;

								.post-salary {
									font-size: 16px;
									color: #ff6600;
									font-weight: bold;
								}
							}

							.position-bd {
								font-size: 12px;
								color: #787878;
								margin-bottom: 12px;
								text-align: left;

								.desc {
									height: 30px;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: normal;
								}

								div {
									margin-bottom: 6px;
								}

								span {
									padding: 0 4px;
									border-right: 1px solid #eee;
								}

								span:first-child {
									padding-left: 0;
								}

								span:last-child {
									border: none;
								}
							}

							.position-card {
								display: flex;
								align-items: center;
								text-align: left;
								padding-top: 12px;
								border-top: 1px dashed #ddd;

								.card-img {
									width: 32px;
									line-height: 32px;
									height: 32px;
									display: flex;
									align-items: center;
									margin-right: 12px;
									background: #fff;
									border-radius: 2px;

									img {
										width: 100%;
									}
								}

								.card-body {
									flex: 1;

									.card-name {
										font-size: 14px;
										color: #333;
									}

									.card-posthot {
										font-size: 12px;
										color: #787878;

										b {
											color: #409eff;
										}
									}
								}
							}
						}

						.position-item:last-child {
							margin-bottom: 0;
						}
					}
				}

				.ad-card {
					display: flex;
					border-radius: 4px;
					overflow: hidden;

					img {
						width: 100%;
					}
				}
			}
		}
	}

	.tishi {
		align-items: center;
		color: #409eff !important;
		background-color: #66b0ff28;
		padding: 10px 0;
		cursor: pointer;
	}

	.tishi-a {
		width: 100%;
		color: #333;
		font-size: 17px;
	}

	.tishi-b {
		width: 100%;
		margin-top: 10px;
	}

	.tishi-c {
		margin-top: 30px;
		width: 100%;
		font-size: 15px;

		.left {
			color: #333;
			font-weight: bold;

			i {
				color: #ff6a6a;
				margin-right: 5px;
			}
		}

		.right {
			color: #409eff;
			cursor: pointer;

			i {
				color: #409eff;
			}
		}
	}

	.tishi-btn {
		margin-bottom: 20px;

		span {
			border: 1px solid #409eff;
			color: #409eff;
			padding: 10px;
			border-radius: 4px;
			cursor: pointer;
			font-size: 14px;
		}

		span:hover {
			background-color: #409eff;
			color: #fff;
		}
	}
</style>
